<template>
  <div class="apply">
    <Container>
      <b-row>
        <b-col md="7" offset-md="1">
          <Card padding="medium" variant="corner-small" class="h-100">
            <Margins>
              <ApplyHeaderPicture class="card__image" />
              <ApplyYourPlan />
              <b-row align-v="center" align-h="center" class="margins__double">
                <b-col cols="auto">
                  <Button variant="link" @click="removeBundle">
                    {{ $t('CANCEL') }}
                  </Button>
                </b-col>
                <b-col cols="auto">
                  <Button
                    :to="{
                      name:
                        isPhysicalStore && !isAdmin
                          ? ROUTES.LOAN_APPLICATION.name
                          : ROUTES.SELECT_STORE.name,
                    }"
                    >{{ $t('APPLY') }}</Button
                  >
                </b-col>
              </b-row>
            </Margins>
          </Card>
        </b-col>
        <b-col md="3" class="margins__mobile-md-double">
          <Card padding="medium" variant="corner-small" class="h-100">
            <Margins>
              <ApplyBenefitsList />
              <Separator size="medium" />
              <ApplyContactForm />
            </Margins>
          </Card>
        </b-col>
      </b-row>
    </Container>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { SET_SELECTED_BUNDLE } from '@/types';
import { Container, Card, Margins, Separator, Button } from '@/components';
import {
  ApplyHeaderPicture,
  ApplyYourPlan,
  ApplyBenefitsList,
  ApplyContactForm,
} from '@/containers/Apply';
import { constants } from '@/mixins';

export default {
  name: 'Apply',
  mixins: [constants],
  components: {
    Container,
    Card,
    Margins,
    Separator,
    Button,
    ApplyHeaderPicture,
    ApplyYourPlan,
    ApplyBenefitsList,
    ApplyContactForm,
  },
  methods: {
    ...mapActions([SET_SELECTED_BUNDLE]),
    removeBundle() {
      this.SET_SELECTED_BUNDLE(null);
      this.$router.push({ name: this.ROUTES.SUBSCRIPTION.name });
    },
  },
  computed: {
    ...mapState(['isPhysicalStore']),
    ...mapGetters(['isAdmin']),
  },
};
</script>
